import React from 'react'
import { Link } from 'gatsby'

import Logo from './logo'
import styles from './header.module.css'

const Header = () => (
  <header className={styles.header}>
    <Link to="/" className={styles.logo}>
      <Logo />
    </Link>
  </header>
)

export default Header
