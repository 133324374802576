import React, { Component } from 'react'
import { Link } from 'gatsby'

import Emoji from '../emoji'
import Colorizer from '../../helpers/colorizer'

import styles from './footer.module.css'

class Footer extends Component {
  componentDidMount() {
    this.colorizer = new Colorizer('footer')
    setInterval(() => this.colorizer.setColor(), 1000)
  }

  render() {
    const year = new Date().getFullYear()
    return (
      <footer className={styles.footer}>
        © {year} <Emoji>🦄</Emoji>{' '}
        <a className={styles.link} href="https://shop.rtsttrs.com">
          shop
        </a>{' '}
        <Emoji>✨</Emoji>{' '}
        <a className={styles.link} href="https://www.instagram.com/rtsttrs/" rel="noopener noreferrer" target="_blank">
          instagram
        </a>{' '}
        <Emoji>🚀</Emoji>{' '}
        <Link className={styles.link} to="/impressum">
          impressum
        </Link>{' '}
        <Emoji>🌈</Emoji>{' '}
      </footer>
    )
  }
}

export default Footer
