import React from 'react'

import styles from './navigationToggleButton.module.css'

const NavigationButton = ({ onClick }) => {
  return (
    <button className={styles.btn} onClick={onClick}>
      <span className={styles.iconBar}></span>
      <span className={styles.iconBar}></span>
      <span className={styles.iconBar}></span>
    </button>
  )
}

export default NavigationButton
