/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../header'
import Navigation from '../navigation'
import Footer from '../footer'

import styles from './layout.module.css'

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNavigationOpen: false
    }
  }

  toggleNavigationOpen = () => {
    const { isNavigationOpen } = this.state
    this.setState({ isNavigationOpen: !isNavigationOpen })
  }

  render() {
    const { children } = this.props
    const { isNavigationOpen } = this.state
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main className={styles.container}>{children}</main>
            <Navigation isOpen={isNavigationOpen} onClick={() => this.toggleNavigationOpen()} />
            <Navigation.ToggleButton onClick={this.toggleNavigationOpen} />
            <Footer />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
