import React from 'react'
import { node, string } from 'prop-types'

const Emoji = ({ children, ariaLabel }) => (
  <span role="img" aria-label={ariaLabel}>
    {children}
  </span>
)

Emoji.propTypes = {
  ariaLabel: string,
  children: node.isRequired
}

Emoji.defaultProps = {
  ariaLabel: ''
}

export default Emoji
