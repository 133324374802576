import React, { Component } from 'react'
import { bool, func } from 'prop-types'
import { Link } from 'gatsby'
import classnames from 'classnames'

import NavigationToggleButton from './navigationToggleButton'

import styles from './navigation.module.css'

class Navigation extends Component {
  constructor(props) {
    super(props)
  }

  isActive = ({ isCurrent }) => (isCurrent ? { className: styles.active } : {})

  render() {
    const { isOpen, onClick } = this.props

    return (
      <div className={classnames(styles.navigation, isOpen ? styles.open : '')}>
        <ul className={styles.navList}>
          <li>
            <a href="https://shop.rtsttrs.com">
              shop
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/rtsttrs/" rel="noopener noreferrer" target="_blank" onClick={onClick}>
              instagram
            </a>
          </li>
        </ul>
        <br />
        <div className={styles.bottom}>
          <Link to="/impressum" getProps={this.isActive} onClick={onClick}>
            Impressum
          </Link>
        </div>
      </div>
    )
  }
}

Navigation.propTypes = {
  isOpen: bool,
  onClick: func
}

Navigation.defaultProps = {
  isOpen: false,
  onClick: () => {}
}

Navigation.ToggleButton = NavigationToggleButton

export default Navigation
